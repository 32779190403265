import React, { useState } from "react";
import Toaster from "./Toaster";

const InfoToast = ({ message, onClose }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Toaster
      open={open}
      message={message}
      severity="info"
      onClose={handleClose}
    />
  );
};

export default InfoToast;
