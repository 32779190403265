import React, { useEffect, useState } from "react";
import { Box, Grid, List, ListItem, ListItemButton, Typography } from "@mui/material";
import Loader from "../components/Loader";

const ReadingMaterial = ({course}) => {
  const [htmlContent, setHtmlContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [toc, setToc] = useState([]);

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(`${course?.reading_material_url}`);
        if (!response.ok) throw new Error("Failed to load HTML file");
        const data = await response.text();
        setHtmlContent(data);
        generateTOC(data); // Generate TOC after loading the HTML
      } catch (error) {
        console.error("Error loading HTML file:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHtmlContent();
  }, []);

  const generateTOC = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const headings = doc.querySelectorAll("h1, h2, h3"); // Select headings to include in TOC

    const tocItems = Array.from(headings).map((heading) => ({
      text: heading.textContent,
      id: heading.id || heading.textContent.replace(/\s+/g, "-").toLowerCase(),
      level: heading.tagName.toLowerCase(), // h1, h2, h3, etc.
    }));

    // Assign IDs to headings if not already present
    tocItems.forEach((item) => {
      const heading = doc.getElementById(item.id);
      if (!heading) {
        const targetHeading = Array.from(doc.querySelectorAll(item.level)).find(
          (h) => h.textContent === item.text
        );
        if (targetHeading) targetHeading.id = item.id;
      }
    });

    setToc(tocItems);
  };

  const handleTOCClick = (id) => {
    const target = document.getElementById(id);
    if (target) {
      // Scroll the content section only
      target.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      {/* TOC Section */}
      <Grid
        item
        xs={3}
        sx={{
          padding: "16px",
          borderRight: "1px solid #ddd",
          backgroundColor: "#f4f4f4",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          paddingTop: "8px",
          overflowY: "auto",
          fontFamily: "Be Vietnam Pro",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: "16px", fontWeight: "bold", fontFamily: "Be Vietnam Pro", }}>
          Table of Contents
        </Typography>
        <List sx={{ padding: 0 }}>
          {toc.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ marginBottom: "0px" }}>
              <ListItemButton
                sx={{
                  paddingLeft: `${(item.level === "h2" ? 1 : item.level === "h3" ? 2 : 0) * 16}px`,
                  borderRadius: "4px",
                  paddingY: "4px",
                }}
                onClick={() => handleTOCClick(item.id)}
              >
                <Typography variant="body1" sx={{ fontWeight: "500", fontFamily: "Be Vietnam Pro", }}>
                  {item.text}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>

      {/* Content Section */}
      <Grid
        item
        xs={9}
        sx={{
          padding: "16px",
          overflowY: "auto", // Ensure content scrolls independently
          maxHeight: "100vh", // Make content section full height
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Box
            sx={{
              overflowWrap: "break-word",
              wordWrap: "break-word",
              maxWidth: "100%",
            }}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ReadingMaterial;
